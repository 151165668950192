.cardContainer {
    margin-top: 20px !important;
}

.productCard{
    margin-top:10px !important;
    margin-bottom:10px !important;
    border: 2px solid gold;
    border-radius: 7px;
}
.productCard .row {
     margin:10px !important
}