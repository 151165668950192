#mainHomeContainer .webPartsContainers {
    margin-top: 50px !important;
}

@media (max-width:767px) {
    #mainHomeContainer div {
        text-align: center;
    }

    #mainHomeContainer h3 {
        margin-top: 20px !important;
    }
}
