.modal-dialog.modal-xl{
    /* width: 70%; */
}

.productCardModal {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    /* border: 2px solid gold; */
    border-radius: 7px;
    padding-right: 0 !important;
}

.productCardModal .row {
    margin: 10px 0px !important
}

#ProcuctsModalBodyMainContainer {
    padding: 10px;
    padding-right: 0 !important;
}

#descMainContaine {
    text-align: left !important;
}

.btnSize {
    margin-left: 10px;
    margin-top: -6px ;
    height: 20px;
    padding: 0px 10px !important;
    font-size: 13px !important;
}

.ItemMainContainer {
    height: 92px;
    width: 76px;
    float: left;
    margin: 9px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #dee2e6;
    border-radius: 9px
}

.btnSize:hover, 
.ItemMainContainer:hover {
    box-shadow: 0px 0px 10px 3px #888888
}

.btnSizeSelected {
    box-shadow: 0px 0px 10px 3px #888888 !important;
    text-shadow: 0 0 black;
}

.ItemMainContainerSelected {
    box-shadow: 0px 0px 8px 3px #888888;
    text-shadow: 0 0 black;
    border-radius: 9px
}

.ItemImg {
    height: 60px;
    width: 74px;
    object-fit: cover;
    border-radius: 7px 7px 0 0
}

.ItemImgSelected {
    height: 56px;
}

.ItemDescContainer {
    position: relative;
    height: 35px;
}

.ItemDesc {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: fit-content;
    font-size: 11px;
}


.btn-secondary{
    --bs-btn-focus-shadow-rgb:none !important;
    background-color: #a0a1a3!important;
    border-color: #a0a1a3!important;
}

.modal-header{
    border-bottom: 2px solid #ffd700;
}

.modal-footer{
    border-top: 2px solid #ffd700;
}

#ProcuctsModalBodyMainContainer h6{
    float: left;
}

#ProcuctsModalBodyMainContainer p{ font-size: 13px; margin-bottom:0 ;}

.clearBoth{
    clear: both;
}
.whatsappBtn{
    margin-top: 15px;
}