@media (max-width:767px) {
    div {
        text-align: center;
    }

    h3 {
        margin-top: 20px !important;
    }
}

.aboutUSContainers{
    margin-bottom: 20px;
}